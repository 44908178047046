.fieldname-list-heading {
  border-bottom: 1px solid black;
}

.fieldname-list-item {
  cursor: pointer;
  margin: 5px 5px;
  color: #ffffff;
  background-color: #555555;
  padding: 0px 2px;
}
