.single-activity-wrapper .page-heading {
  padding: 20px 0px 0px 20px;
}

.single-activity-graphs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  padding: 25px;
}

.single-activity-graph {
  position: relative;
  background: #fff;
  min-height: 240px;
  height: 300px;
  display: grid;
  grid-template-areas:
    "label time"
    "graph graph";
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #aaa;
  padding: 20px;

  h2 {
    font-size: 18px;
    margin: 0px;
    grid-area: label;
  }

  p {
    font-size: 12px;
    margin: 0px;
    grid-area: time;
    text-align: right;
  }

  .graph-wrapper {
    grid-area: graph;
  }
}
