.payment-method {
  border: 1px solid #000000;
  padding: 10px;
  margin-top: 10px;

  &:first-child {
    margin-top: 0px;
  }
}

.selected-payment-method {
  background-color: lightblue;
}

.payment-method-default-tag {
  display: inline-block;
  margin-left: 10px;
  color: #555555;
  font-style: italic;
}
