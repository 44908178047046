.email-modal {
  position: absolute;
  z-index: 2;
  background: #ffffff;
  padding: 10px;
  min-width: 400px;
  min-height: 400px;
  top: 44px;
  right: 0px;
  box-shadow: -5px 8px 5px #00000033;
}

.email-form {
  padding-top: 20px;
}

.email-form-item {
  width: 100%;
  padding-right: -100px;
}

.email-dropdown-options,
.email-input {
  height: 30px;
}

.email-input,
.email-textarea {
  width: 100%;
  border-width: 1px;
}

.email-textarea {
  min-height: 150px;
  min-width: 400px;
  max-height: 550px;
  max-width: 400px;
  resize: none;
}

.modal-footer {
  background-color: #eceee8;
  text-align: right;
  margin: 20px -10px -10px;
}

.send-report-button {
  background: #44aa44;
  color: #ffffff;
  border: none;
  text-align: center;
  font-weight: bolder;
  padding: 10px 15px;
  margin: 10px 10px 10px 50px;
  cursor: pointer;
}

// .email-report-link {
//   // position: absolute;
//   color: #ffffff;
//   // z-index: 1;
//   padding: 10px;
//   // top: 45px;
//   // right: 65px;
//   cursor: pointer;
//   margin-left: auto;
// }

.email-modal-closed,
.email-warning-off {
  display: none;
}

.email-warning-on {
  color: red;
}

.email-sending {
  color: lightgray;
  border-color: lightgray;
}

.email-sending-send-button {
  background-color: #b2e0b2;
  cursor: pointer;
}
