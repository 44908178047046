.subscription-panel {
  position: relative;
  border: 1px solid #000000;
  background: #ffffff;
  padding-bottom: 80px;
}

.subscription-update-button {
  background: #00aaff;
  color: #ffffff;
  border: none;
  display: block;
  margin-left: auto;
  margin-right: 0px;
  padding: 5px 10px;
  cursor: pointer;

  &:disabled {
    background: #cccccc;
    color: #333333;
    cursor: default;
  }
}

.subscription-panel-bottom {
  border-top: 1px solid #888888;
  position: absolute;
  bottom: 10px;
  left: 0px;
  right: 0px;
  margin: 0px 10px;
  padding-top: 10px;
}

.subscription-subtotal-wrapper {
  display: grid;
  margin: 0px 0px 10px;
  grid-template-columns: 3fr 1fr;
}

.subscription-subtotal-label,
.subscription-subtotal {
  text-align: right;
}
