@use "../../variables";

.global-nav {
  background: variables.color("global-nav", "background"); //#263238;
  display: flex;
  flex-direction: row;
  color: variables.color("global-nav", "color");
  align-items: center;
  padding: 0px 10px;
}

.logo {
  width: 25px;
  height: 25px;
  // border: 1px solid variables.color("global-nav", "color");
}

.global-nav .right {
  margin-left: auto;
}

.search-box {
  margin-right: 50px;
  background: variables.color("global-nav", "search-box", "background"); //#44555d;
  border: none;
  padding: 10px;
  color: variables.color("global-nav", "search-box", "color");
  caret-color: variables.color("global-nav", "search-box", "color");
  outline: none;
}

.search-box::placeholder {
  color: variables.color("global-nav", "color");
  opacity: 0.5;
}

.nav-menu-heading {
  margin: 0px 7px;
  cursor: pointer;
}
