@use "../../variables";

.delete-dashboard-btn {
  background: variables.color("danger", "background");
  color: variables.color("danger", "color");
  border: none;
  padding: 5px;
  cursor: pointer;
}

.no-cards {
  position: absolute;
  margin: 0px;
}

.delete-dashboard-wrapper {
  padding: 10px;
}

.delete-dashboard-buttons {
  display: flex;
  justify-content: space-between;

  * {
    cursor: pointer;
  }
}

.delete-dashboard-confirm {
  background: variables.color("danger", "background");
  color: variables.color("danger", "color");
}
