.key {
  fill: none;
  stroke: none;
  cursor: pointer;
}

.key-color {
  width: 10px;
  height: 10px;
  pointer-events: none;
}

.key-label {
  font-family: Arial, Helvetica, sans-serif;
  dominant-baseline: middle;
  user-select: none;
}

.legend {
  /* opacity: 0; */
}

.legend-background {
  cursor: move;
}
