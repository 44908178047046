.chip {
  display: inline-flex;
  padding: 0.25em 0.5em;
  background: #555555;
  color: #ffffff;
  align-items: center;
}

.chip-delete {
  position: relative;
  width: 12px;
  height: 12px;
  margin-left: 10px;
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: #cccccc;
    top: 50%;
    left: 50%;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.email-sending-chip {
  background-color: #bbbbbb;
}
