.dashboard {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 256px;
  // grid-template-rows: repeat(auto-fill, 256px);
  gap: 20px;
  padding: 30px;
}

.card {
  &.text-card {
    grid-column-end: span 1;
    grid-row-end: span 1;
  }

  &.graph-card {
    grid-column-end: span 2;
    grid-row-end: span 2;
  }

  &.width-1 {
    grid-column-end: span 1;
  }

  &.height-1 {
    grid-row-end: span 1;
  }

  &.width-2 {
    grid-column-end: span 2;
  }

  &.height-2 {
    grid-row-end: span 2;
  }

  &.width-3 {
    grid-column-end: span 3;
  }

  &.height-3 {
    grid-row-end: span 3;
  }

  &.width-4 {
    grid-column-end: span 4;
  }

  &.height-4 {
    grid-row-end: span 4;
  }

  &.width-5 {
    grid-column-end: span 5;
  }

  &.height-5 {
    grid-row-end: span 5;
  }

  &.width-6 {
    grid-column-end: span 6;
  }

  &.height-6 {
    grid-row-end: span 6;
  }
}
