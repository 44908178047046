.edit-table-wrapper {
  padding: 20px;

  .data-table {
    table-layout: fixed;
  }

  .extended-table {
    width: unset;

    th {
      font-size: 12px;
    }

    th,
    td {
      padding: 5px 10px;
    }

    td span,
    td input,
    th span,
    th input {
      display: block;
      width: 40px;
    }

    td:first-child span,
    td:first-child input,
    th:first-child span,
    th:first-child input {
      display: block;
      width: 100px;
    }

    .save-th {
      column-span: 2;
    }
  }

  th,
  th:first-child {
    width: unset;
    min-width: calc(100% / 6);
    border-left: 0px;
    border-right: 0px;
    padding: 5px 10px;
  }

  th:first-child {
    text-align: left;
  }

  td:first-child {
    text-align: left;
  }

  td {
    text-align: center;
    padding: 5px 2em;
  }

  .input-td {
    background: #ffffed;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
  }

  td input {
    text-align: right;
    min-width: 40px;
  }
}

.table-input {
  width: 100%;
}

.save-button:disabled {
  opacity: 0.25;
  cursor: default;
}

.save-button {
  background: #22bb22;
  color: #ffffff;
  border: 1px solid #009900;
  border-radius: 3px;
  padding: 5px 25px;
  opacity: 1;
  cursor: pointer;
  justify-content: end;
}

.month-controls {
  text-align: center;

  th {
    background: #ffffff;
  }

  .next-button,
  .prev-button {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin: auto;
    color: #00aacc;
    background: #ffffff;
    border: 1px solid #bbbbbb;
    padding: 5px 10px;
  }

  .button-icon {
    display: block;
    width: 10px;
    height: 10px;
  }

  .prev-button .button-icon {
    margin-right: 10px;
    border-top: 5px solid transparent;
    border-right: 5px solid #bbbbbb;
    border-bottom: 5px solid transparent;
  }

  .next-button .button-icon {
    margin-left: 10px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #bbbbbb;
  }
}
