.sub-menu-wrapper {
  position: relative;
  cursor: pointer;
  user-select: none;
  &.closed {
    .sub-menu {
      display: none;
      height: 0px;
    }

    .menu-item::after {
      transform: translate(-3px, -6px);
    }
  }

  &.open {
    .menu-item::after {
      transform: translate(0px, -3px) rotate(-90deg);
    }
  }
}

.menu-item {
  color: #eeeeee;
  padding: 7px 10px;
  margin: 0px;
  border-bottom: 1px solid #ffffff11;
}

.menu-item:hover {
  background: #44555d;
}

.menu-item::after {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  position: absolute;
  right: 20px;
  top: 1em;
  border-left: 6px solid #cccccc00;
  border-right: 6px solid #cccccc;
  border-top: 6px solid #cccccc00;
  border-bottom: 6px solid #cccccc00;
  transition: transform 0.4s;
}
