.current-wrapper {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.current-disabled {
  color: #dddddd;
}

.custom-date-range-heading {
  margin-bottom: 0px;
}

.range-error {
  color: red;
}

.no-range-error {
  visibility: hidden;
}

.custom-date-button {
  background: #0077aa;
  color: #ffffff;
  border: none;
  padding: 3px;
  margin-left: 10px;
  cursor: pointer;
}

.custom-date-picker {
  cursor: text;
}
.custom-date-picker::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.quarter-section {
  margin: 30px 0px;
}
