.metrix-logo {
  text-align: center;
  font-size: 80px;
  color: #0088ff;
}

.pre-auth-form {
  border: 1px solid #000000;
  background: #ffffff;
  width: 100%;
  max-width: 300px;
  padding: 15px;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 0px);

  label {
    margin-top: 10px;
    display: block;
  }

  .submit-button {
    display: block;
    margin: 10px auto 0px;
    min-width: 150px;
    cursor: pointer;
  }

  input {
    width: 100%;
    border: 1px solid transparent;
    background: #f0f0f0;
    outline: none;
    padding: 5px;
    border-radius: 2px;

    &:focus {
      border: 1px solid #000000;
    }

    &.error {
      border: 1px solid #ff0000;
    }
  }

  .link-button {
    border: none;
    background: none;
    color: #0088ff;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .right-link {
    display: block;
    margin-left: auto;
    margin-top: 10px;
    padding: 0px;
  }

  .change-link {
    font-size: 14px;
    margin-bottom: 0px;
    color: #777777;

    .link-button {
      font-size: 14px;
    }
  }

  .auth-heading {
    font-size: 24px;
    margin: 0px;
  }
}
