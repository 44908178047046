@use "../../variables";

.toast-container {
  position: absolute;
  top: 0px;
  right: 0px;
  overflow: auto;
}

.toast {
  // position: absolute;
  // top: 0px;
  // right: 0px;
  min-width: 200px;
  max-width: 360px;
  min-height: 30px;
  border: 1px solid #000;
  border-radius: 0px;
  background: #ffffff;
  overflow: hidden;
  display: grid;
  grid-template-columns: 50px 1fr 30px;
  grid-template-rows: 1fr;
  grid-template-areas: "id msg cl";
  align-items: center;
  opacity: 1;
  transition: opacity 0.5s ease-out;

  .identifier {
    grid-area: id;
    background: variables.color("success", "background");
    width: 100%;
    height: 100%;
    border-right: 1px solid #000000;

    &.error {
      background: variables.color("danger", "background");
    }
  }

  .message {
    margin: 0px;
    padding: 5px;
    grid-area: msg;
  }

  .close {
    position: relative;
    background: #00000000;
    width: 100%;
    height: 100%;
    cursor: pointer;
    grid-area: cl;

    &:hover {
      background: #00000022;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      width: 60%;
      height: 2px;
      background: #000;
      position: absolute;
      left: 50%;
      top: 50%;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &.closing {
    opacity: 0;
  }
}
