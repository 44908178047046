.create-user-modal {
  .modal-content {
    padding: 10px;
    min-width: 300px;
  }

  .create-user-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .create-user-column:first-child {
    margin-right: 10px;
  }

  .create-user-label {
    display: block;
  }

  .create-user-input {
    border: 1px solid #000000;
    border-radius: 3px;
    padding: 5px;
    margin-bottom: 10px;
    width: 100%;
  }

  .create-user-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .create-user-button {
    width: 100%;

    &:first-child {
      margin-right: 20px;
    }
  }

  .create-user-cancel-button {
    background: linear-gradient(#eeeeee, #cccccc);
    border: 1px solid #cccccc;
    color: #333333;
    border-radius: 1px;
    padding: 5px 15px;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 1px 2px 1px #00000033;
    }
  }

  .create-user-confirm-button {
    background: linear-gradient(#00bb00, #009900);
    color: #ffffff;
    border: none;
    padding: 5px 15px;
    border-radius: 1px;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 1px 2px 1px #00aa00aa;
    }
  }

  .create-user-cancel-button:disabled,
  .create-user-confirm-button:disabled {
    background: linear-gradient(#eeeeee, #cccccc);
    color: #888888;
    cursor: default;

    &:hover {
      box-shadow: none;
    }
  }
}
