.selected-employee {
  background: #eceee8;
  border: 1px solid #aaaaaa;
  padding: 10px;
  margin-bottom: -25px;
  min-height: 50px;
  max-height: 75px;
  position: relative;
}

.not-selected {
  background: #ffffff;
  display: inline-block;
}

.selected-employee-input {
  width: 100%;
  margin-top: 5px;
}

.not-selected-input {
  display: none;
}

.addTagUnselected {
  display: none;
}

.addTagSelected {
  border: 1px solid black;
  background-color: #ffffff;
  overflow: auto;
  max-height: 205px;
  position: relative;
}

.dropdownTag {
  font-weight: bold;
  border: 1px solid black;
  width: 100%;
  background-color: #eceee8;
  padding: 5px 0px 5px 10px;
  cursor: pointer;
}

.dropdownTag:hover {
  background-color: #dbddd7;
}

// .assigned-tags-display {

// }
