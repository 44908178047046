.paymentForm {
  margin: 10px;
  padding: 10px;
  border: 1px solid black;
}

.payment-submission-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.payment-submit-button {
  background: #44aa44;
}

.payment-cancel-button {
  background: #dd0000;
}

.payment-submit-button,
.payment-cancel-button {
  color: #ffffff;
  border: none;
  padding: 5px 15px;
  cursor: pointer;

  &:disabled {
    background: #cccccc;
    color: #888888;
    cursor: default;
  }
}
