.chip-input-wrapper {
  position: relative;
}

.chip-input {
  height: 30px;
  width: 100%;
}

.chips {
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
  overflow: auto;
}

.autofill-options {
  position: absolute;
  top: 100%;
  width: 100%;
  border: 1px solid #000;
  max-height: 350px;
  overflow: auto;
  background: #ffffff;
  z-index: 1;
  text-align: left;

  &.closed {
    display: none;
  }

  &.open {
    display: block;
  }
}

.autofill-option {
  background: #fff;
  line-height: 0px;
  padding: 2px;
  border-top: 1px solid #000000;
  cursor: pointer;
}

.autofill-option:focus,
.autofill-option:hover {
  background: #eefaff;
}
