.range-picker {
  position: relative;
  margin: 20px 0px;
  font-size: 13px;
}

.date-range-selector,
.interval-selector,
.filter-selector {
  position: absolute;
  z-index: 2;
  border: 1px solid #000;
  background: #ffffff;
  padding: 10px;
}

.date-range-selector {
  min-width: 400px;
}

.interval-selector {
  min-width: 150px;
}

.header-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #0077aa;
  color: #ffffff;
  margin: -10px -10px 0px -10px;
  padding: 10px;
}

.selector-header {
  font-size: 20px;
  margin: 0px;
}

.selector-exit {
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background: none;
  border: none;
}

.selector-exit::before,
.selector-exit::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 3px;
  background: #ffffff;
}

.selector-exit::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.selector-exit::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.date-range-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.date-range-heading {
  margin: 10px 5px 0px 5px;
}

.date-range-link {
  color: #0077aa;
  margin: 10px 5px 0px 5px;
  cursor: pointer;
  text-decoration: underline;
  font-size: 13px;
  font-weight: bold;
}

.date-range-link.active {
  color: #00aaff;
}

.interval-section {
  display: flex;
  flex-direction: column;
}

.date-range-link-wrapper {
  display: inline-block;
  position: relative;
}
