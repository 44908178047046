.filter-selector {
  width: 600px;
}

.filter-default-buttons {
  text-align: right;
}

.set-default-button,
.reset-default-button {
  background: #eceee8;
  color: #888888;
  border: none;
  padding: 10px;
  margin: 10px 0px 10px 10px;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

.add-filter-button {
  width: 100%;
  color: #0077aa;
  font-weight: bold;
  background: white;
  border: 1px dashed black;
  cursor: pointer;
  padding: 10px;
  margin: 10px 0px 0px 0px;
}

.configure-filters-button,
.apply-filters-button {
  margin: 10px 0px 0px 10px;
  cursor: pointer;
}

.filter-selector-footer {
  text-align: right;
  background: #eceee8;
  margin: 10px -10px -10px -10px;
  padding: 0px 10px 10px 10px;
}

.configure-filters-button {
  font-weight: bold;
  color: white;
  background: #888888;
  border: none;
  padding: 10px;
}

.apply-filters-button {
  font-weight: bold;
  color: white;
  background: #44aa44;
  border: none;
  padding: 10px;
}

.filters-link-wrapper {
  color: #0077aa;
  margin: 10px 5px 0px 5px;
  cursor: pointer;
  text-decoration: underline;
  font-size: 13px;
  font-weight: bold;
}

.filter-card {
  background-color: #eceee8;
  height: 50px;
  position: relative;
}

.filter-card-taglist,
.inclusion-toggle,
.filter-card-tag-list {
  padding: 5px;
  margin: 10px;
}

.delete-filter {
  cursor: pointer;
}

.filter-tag-list-off,
.filter-type-list-off {
  display: none;
}

.filter-tag-list-on,
.filter-type-list-on {
  background: #eceee8;
  border: 1px solid black;
  position: absolute;
  z-index: 1;
}

.filter-type-list-on {
  width: 31.5%;
  margin: -10px 0px 0px 10px;
}

.filter-tag-list-on {
  width: 31.5%;
  margin: -10px 0px 0px 322px;
}

.filter-type-item:first-child {
  border: none;
}

.filter-type-item {
  position: relative;
  display: block;
  border: 0px;
  border-top: 1px solid black;
  padding: 5px 0px 5px 10px;
  width: 100%;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background: #fafafa;
    color: #0077aa;

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0px;
      top: 0px;
      bottom: 0px;
      width: 4px;
      background: #00aaff;
    }
  }
}

.filtertypeSelected {
  display: inline;
}

.filterTypeRequired {
  display: inline;
  visibility: hidden;
}
