.data-table:hover {
  .filter-symbol {
    opacity: 0.5;
  }
}

.data-table .filter-symbol {
  position: relative;
  width: 15px;
  height: 15px;
  opacity: 0.25;
  cursor: pointer;

  $regular-color: #999999;
  $active-color: #00aaff;

  &.active {
    &::before {
      border-top: 6px solid $active-color;
    }

    &::after {
      border-left: 2px solid $active-color;
      border-top: 2px solid $active-color;
      box-shadow: 0px -4px 0px 0px $active-color;
    }
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
  }

  &::before {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-top: 6px solid $regular-color;
    left: 50%;
    top: 0px;
    transform: translate(-50%, 0px);
  }

  &::after {
    border-left: 2px solid $regular-color;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-top: 2px solid $regular-color;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0px);
    box-shadow: 0px -4px 0px 0px $regular-color;
  }

  &.active,
  &:hover {
    opacity: 1;
  }
}

.column-filter-wrapper {
  position: relative;
}

.column-filter-list {
  position: absolute;
  border: 1px solid #000000;
  background: #f9f9f9;
  padding-bottom: 5px;
  top: 60%;
  left: 45%;
  border-radius: 5px;
  z-index: 1;
  overflow: hidden;

  &.flipped {
    left: unset;
    right: 45%;
  }
}

.column-filter-list-header {
  width: 100%;
  height: 17px;
  background: #f0f0f0;
  border-bottom: 1px solid #dddddd;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.column-filter-list-close {
  position: relative;
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-right: 1px;
  border: none;
  border-radius: 3px;

  &:hover {
    background: #dfdfdf;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 10px;
    height: 2px;
    background: #ee0000;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.column-filter-list-body {
  display: grid;
  width: 300px;
  grid-template-columns: 0px repeat(3, calc((100% - 45px) / 3)) 20px;
  gap: 5px;
  min-width: fit-content;
  padding: 5px;
  justify-items: center;

  * {
    width: 100%;
  }
}

.column-filter-list-add {
  grid-column: 2 / span 1;
}

.column-filter-list-apply {
  grid-column: 4 / span 1;
}
