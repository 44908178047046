@use "../../variables";

.modal-background {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background: #00000055;
  z-index: 2;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3px;
  background: #333333;
  border-radius: 5px;
  z-index: 2;

  &.danger {
    .modal-title-bar {
      background: variables.color("danger", "background");

      .modal-exit {
        &::before,
        &::after {
          background: variables.color("danger", "color");
        }
        
        &:hover {
          &::before,
          &::after {
            background: variables.color("danger", "hover");
          }
        }
      }
    }

    
  }

  &.warning {
    .modal-title-bar {
      color: variables.color("warning", "color");
      background: variables.color("warning", "background");

      .modal-exit {
        &::before,
        &::after {
          background: variables.color("warning", "color");
        }
        
        &:hover {
          &::before,
          &::after {
            background: variables.color("warning", "hover");
          }
        }

      }
    }
  }

  &.success {
    .modal-title-bar {
      background: variables.color("success", "background");

      .modal-exit {
        &::before,
        &::after {
          background: variables.color("success", "color");
        }

        &:hover {
          &::before,
          &::after {
            background: variables.color("success", "hover");
          }
        }
      }
    }
  }

  .modal-title-bar {
    color: #ffffff;
    padding: 10px;
    display: flex;
    justify-content: space-between;

    .modal-title {
      margin: 0px;
      font-size: 15px;
      font-weight: bold;
    }

    .modal-exit {
      position: relative;
      width: 20px;
      height: 20px;
      cursor: pointer;
      border-radius: 50%;

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        left: 50%;
        top: 50%;
        background: #777777;
      }

      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &:hover {
        &::before,
        &::after {
          background: #aaaaaa;
        }
      }
    }
  }

  .modal-content {
    background: #ffffff;
    overflow: auto;
  }
}
