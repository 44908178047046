.entity-page {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  // grid-auto-rows: minmax(50px, auto);
  padding: 20px;
  overflow: auto;
  height: 100%;
  gap: 20px;
}

.entity-header {
  margin: 0px;
  font-size: 24px;
}

.entity-section-header {
  display: flex;
  margin: 0px;
  background: #00aadd;
  color: #ffffff;
  font-size: 18px;
  padding: 10px;
  max-width: 150px;
  margin-left: -20px;
  align-items: center;
  justify-content: center;
}

.entity-section-subheader {
  display: flex;
  color: #ff0000;
  height: 100%;
  font-size: 14px;
  align-items: center;
}

.entity-data-point {
  .entity-data-point-value {
    font-size: 22px;
    margin: 0px;
    padding: 0px 0px 5px;
  }

  .entity-data-point-label {
    border-top: 1px solid #aaaaaa;
    font-size: 12px;
    color: #aaaaaa;
    margin: 0px;
    padding: 5px 0px 0px;
  }
}

.entity-info-label {
  margin: 0px;
  font-size: 16px;

  .entity-info-value {
    margin-left: 6px;
    color: #cc9900;
    font-size: 16px;
  }
}

.entity-graph-table-wrapper {
  // display: flex;
  // flex-direction: column;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;
  min-height: auto;

  .entity-graph-table-label {
    margin: 0px;
  }

  .entity-graph-table {
    background: #cccccc;
    width: 100%;
    height: 100%;
  }

  .page-graph {
    height: 100%;
    margin-bottom: 0px;
  }

  .graph-table {
    padding: 0px;
    min-height: 100px;
    width: 100%;
    // height: 100%;
    // height: auto;
    // flex-grow: 1;
  }
}

$columns: 12;

@for $width from 1 through $columns {
  .width-#{$width} {
    grid-column-end: span $width;
  }
}

$rows: 4;

@for $height from 1 through $rows {
  .height-#{$height} {
    grid-row-end: span $height;
  }
}
