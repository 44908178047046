@use "../../variables";

.report-form-row {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 8fr 1fr;
  padding: 10px 10px 20px 10px;
  border-bottom: 1px solid #555555;
  background: #f7f7f7;

  &:last-child {
    border: none;
  }

  .report-form-left,
  .report-form-middle,
  .report-form-right {
    padding: 0px;

    .report-form-label {
      position: relative;
      display: inline-block;
      margin-bottom: 3px;
      overflow: visible;
    }

    .report-form-tip-label {
      margin-left: 5px;
      position: relative;
      display: inline-block;

      &::after {
        display: block;
        content: "?";
        font-size: 12px;
        font-weight: bold;
        width: 1.5em;
        height: 1.25em;
        background: #aaaaaa;
        text-align: center;
        padding-top: 0.25em;
        color: #ffffff;
        border-radius: 100%;
        box-shadow: inset 0px 0px 3px 0px #000000;
        cursor: help;
      }

      .report-form-tooltip {
        display: none;
        background: #ffffff;
        border: 1px solid #555555;
        padding: 5px;
        white-space: pre;
      }

      &:hover {
        .report-form-tooltip {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
        }
      }
    }

    .report-form-url,
    .report-form-subject,
    .report-form-frequency,
    .report-form-emails,
    .report-form-date {
      display: block;
      margin-bottom: 10px;
      width: 100%;
    }

    .report-form-date {
      margin-bottom: 0px;
    }

    .report-form-message {
      resize: none;
      width: 100%;
      height: 100%;
    }
  }

  .report-form-left {
    padding-right: 10px;
  }

  .report-form-middle {
    display: flex;
    flex-direction: column;
    border-left: 1px solid #999999;
    border-right: 1px solid #999999;
    padding: 0px 10px;
  }

  .report-form-right {
    display: flex;
    flex-direction: column;
    padding-top: calc(1em + 5px);
    padding-left: 10px;

    .report-form-button {
      margin-bottom: 10px;
      cursor: pointer;
    }
  }

  .report-form-delete {
    background: variables.color("danger", "background");
    color: variables.color("danger", "color");
  }
}

.delete-report-modal {
  .modal-content {
    padding: 10px;
  }

  .delete-report-modal-buttons {
    display: flex;
    justify-content: space-between;

    button {
      cursor: pointer;
    }
  }

  .delete-report-modal-button {
    background: variables.color("danger", "background");
    color: variables.color("danger", "color");
  }
}
