.table-wrapper {
  // overflow: auto;
  position: relative;
}

.placeholder {
  position: relative;
  min-height: 520px;
  border: 1px solid #cccccc;
}

.data-table {
  position: relative;
  width: 100%;
  border-spacing: 0;
  border: 1px solid #cccccc;
  border-top: none;
  border-bottom: none;
  background: #dddddd;
}

.data-table::before {
  content: "";
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  top: 0px;
  left: 0px;
  pointer-events: none;
}

.data-table th {
  background: #f9f9f9;
  border: 1px solid #cccccc;
  border-right: none;
  padding: 5px 10px;
  font-size: 14px;
}

thead tr:first-of-type th {
  border-bottom: none;
}

.data-table th:first-child {
  border-left: none;
}

.data-table th:last-child {
  border-right: none;
}

.data-table td {
  border-bottom: 1px solid #cccccc;
  padding: 5px 10px;
  font-size: 14px;
  position: relative;
}

.data-table .check-all {
  padding-left: 2em;
  padding-right: 2em;
  width: 0px;
}

.data-table tr.inset {
  border-left: 2em solid #00000000;
  color: #777777;
}

.expandable {
  cursor: pointer;
}

.expandable::after {
  content: "";
  display: block;
  position: absolute;
  border-left: 5px solid #9296a0;
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  transition: transform 0.25s;
}

.expandable.expanded::after {
  transform: translate(-50%, -25%) rotate(90deg);
}

.data-table .check {
  text-align: center;
}

.data-table tbody {
  background: #ffffff;
}

.data-table tr:hover {
  background: #eefaff;
}

th .column-label {
  text-decoration: underline dashed #555555 1px;
  cursor: help;
}

.data-table:hover .sort-up,
.data-table:hover .sort-down {
  opacity: 0.5;
}

.sort-up,
.sort-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  cursor: pointer;
}

$regular-color: #999999;
$active-color: #00aaff;

.sort-up {
  border-bottom: 5px solid $regular-color;
  margin-bottom: 5px;
  opacity: 0.25;
}

.data-table .sort-up.active {
  border-bottom: 5px solid $active-color;
  opacity: 1;
}

.sort-down {
  border-top: 5px solid $regular-color;
  opacity: 0.25;
}

.data-table .sort-down.active {
  border-top: 5px solid $active-color;
  opacity: 1;
}

.data-table {
  .sort-up:hover,
  .sort-down:hover {
    opacity: 1;
  }
}

.pagination-button-wrapper {
  display: flex;
  gap: 5px;
}

.pagination-button {
  background: #f9f9f9;
  border: 1px solid #cccccc;
  padding: 5px 10px;
  min-width: 37px;
  cursor: pointer;

  &:disabled {
    background: #dddddd;
  }

  &.current-page {
    background: #22bbff;
    color: #ffffff;
  }
}

.sticky-thead {
  position: sticky;
  inset-block-start: 0;
  z-index: 1;
}

.table-link {
  text-decoration: none;
  color: #0088dd;
  font-weight: bold;

  &:hover {
    color: #22bbff;
  }
}

.hoverable {
  position: relative;
  color: #0088dd;
  .hover-container {
    display: none;
    padding: 5px;
    width: 300px;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;
  }
  &:hover {
    .hover-container {
      display: block;
      position: absolute;
      color: #ffffff;
      background: #555555;
    }
  }
}

.selectedRow {
  background-color: #d1faff;
  position: sticky;
  bottom: 0px;
}

.selectedRowtd {
  border-top: 1px solid #00ddff;
  border-bottom: 1px solid #00ddff !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.totalRow {
  background-color: #e0ffd1;
}

.column-sort-table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dashboard-card {
  background-color: #ffffff;
}
