.pin-modal-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.pin-modal {
  max-width: 650px;
  overflow: hidden;
}

.modal-closed {
  display: none;
}

.dashboard-pin-settings {
  margin-top: 10px;
}

.dashboard-selector,
.pin-card-heading-section {
  display: grid;
  grid-template-columns: 130px 1fr;
  max-width: 400px;
}

.pin-save-button {
  float: right;
}

.card-dimensions {
  display: inline;
}

.card-height-section {
  padding-left: 50px;
}

.card-dimension-selector {
  margin-left: 5px;
}

.page-section-selectors {
  display: flex;
  margin: 5px 0px 10px 0px;
  justify-content: space-evenly;
}

.dashboard-selector {
  margin-bottom: 10px;
}

.include-legend-section {
}

.include-legend-section-hide {
  display: none;
}

.text-card-options {
  display: grid;
  grid-template-columns: 1fr repeat(3, 2fr);
  gap: 5px;
  margin-bottom: 5px;
}

.text-card-options-hidden {
  display: none;
}

.fieldname-select {
  display: grid;
  margin-top: 10px;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.fieldname-select-hidden {
  display: none;
}

.list-section {
  display: flex;
  flex-direction: column;
}

.display-new-dashboard-section {
  margin-bottom: 10px;
}

.hide-new-dashboard-section {
  display: none;
}

.pin-to-dash-button {
  cursor: pointer;
  background: #0077aa;
  color: #ffffff;
}

.pin-modify-button {
  margin-left: 0px;
}

.modal.modify-modal {
  max-width: 480px;

  .modal-content {
    padding: 5px 10px;
    text-align: center;
  }

  .modify-buttons {
    display: flex;
    justify-content: space-between;

    button {
      cursor: pointer;
    }
  }
}
