@use "../../variables";

.dashboard-card-settings-wrapper {
  position: relative;
  margin-right: 10px;
}

.dashboard-card-settings {
  position: absolute;
  z-index: 2;
  box-shadow: 0px 0px 5px #00000088;
  top: 0px;
  right: 0px;
  min-width: 180px;

  .header-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #0077aa;
    color: #ffffff;
    padding: 10px;
    margin: 0px;
  }

  .selector-header {
    font-size: 20px;
    margin: 0px;
  }

  .selector-exit {
    position: relative;
    width: 25px;
    height: 25px;
    cursor: pointer;
    background: none;
    border: none;
  }
}

.card-settings-body-section {
  display: grid;
  // grid-template-columns: 2fr 1fr;
  grid-template-areas:
    "csl1 csl1 css1"
    "csl2 csl2 css2"
    "csl3 csl3 css3"
    "cdb . cub";
  gap: 10px;
  background: white;
  padding: 5px;
  // margin: 0px -10px;
  // border-left: 1px solid black;
  // border-bottom: 1px solid black;
  // border-right: 1px solid black;
}

.card-settings-option {
  margin-bottom: 5px;
}

.card-settings-option-label {
  font-size: 16px;
  font-weight: normal;
}

.card-settings-option-label:nth-of-type(1) {
  grid-area: csl1;
}

.card-settings-option-label:nth-of-type(2) {
  grid-area: csl2;
}

.card-settings-option-label:nth-of-type(3) {
  grid-area: csl3;
}

.card-delete-button,
.card-update-button {
  display: inline;
  color: #ffffff;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.card-delete-button {
  background: #dd0000;
  grid-area: cdb;
}

.card-update-button {
  background: #0077aa;
  grid-area: cub;
}

.card-settings-select:nth-of-type(1) {
  grid-area: css1;
}
.card-settings-select:nth-of-type(2) {
  grid-area: css2;
}
.card-settings-select:nth-of-type(3) {
  grid-area: css3;
}

.delete-card-confirmation {
  p {
    margin: 15px 0px;
    font-size: 14px;
    font-weight: normal;
  }

  .modal-content {
    padding: 5px 10px;
  }

  .delete-card-confirmation-buttons {
    display: flex;
    justify-content: space-between;

    * {
      cursor: pointer;
    }

    .delete-card-confirmation-button {
      background: variables.color("danger", "background");
      color: variables.color("danger", "color");
    }
  }
}
