.license-wrapper {
  padding: 20px;
}

.license-header {
  margin-left: auto;
  margin-right: 0px;
  width: fit-content;
}

.buy-more-license-button,
.add-user-button {
  margin-left: 15px;
  padding: 7px 15px;
  background: #00aaff;
  border: none;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background: #00bbff;
  }
}

.access-licenses {
  display: inline-block;
  padding: 10px;
  background: #e9e9e9ff;
  position: sticky;
  top: 0px;
  border: 1px solid #555555;
  // line-height: 1.5em;
  // box-shadow: 0px 0px 5px #000000aa;

  p {
    margin: 0px;
    &:first-child {
      padding-bottom: 5px;
    }

    span {
      background: #ffffff;
      padding: 5px;
    }
  }
}

.save-licensing-button {
  background: #00aa00;
  color: #ffffff;
  border: none;
  cursor: pointer;
  padding: 7px 10px;

  &:hover {
    background: #008800;
  }
}

.license-table {
  border: 1px solid #cccccc;
  border-collapse: collapse;
  font-size: 12px;
  width: 100%;

  thead {
    background: linear-gradient(#eeeeee, #cccccc);
  }

  tbody {
    background: #ffffff;
  }

  th,
  td {
    padding: 3px 10px;
  }

  tr {
    border-bottom: 1px solid #cccccc;

    &:last-of-type {
      border: none;
    }
  }

  .enabled-cell {
    text-align: center;
    width: 150px;
  }

  .name-cell,
  .email-cell,
  .id-cell {
    text-align: left;
  }

  .entities-cell {
    text-align: right;
  }

  .id-cell {
    text-align: left;
    width: 100px;
  }

  .delete-cell {
    width: 150px;
    text-align: center;

    .delete-button {
      color: #ffffff;
      background: #990000;
      border: none;
      padding: 5px 10px;
      cursor: pointer;

      &:hover {
        background: #cc0000;
      }
    }

    .delete-button:disabled {
      background: linear-gradient(#eeeeee, #cccccc);
      color: #888888;
      cursor: default;

      &:hover {
        background: linear-gradient(#eeeeee, #cccccc);
      }
    }
  }
}

.table-label:first-of-type {
  margin: 0px 0px 10px;
}

.table-label {
  margin: 20px 0px 10px;
}
