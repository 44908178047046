.column-filter-value {
  grid-column: 2 / span 1;
}

.column-filter-select {
  grid-column: 3 / span 1;
  // text-align: center;
}

.column-filter-input {
  grid-column: 4 / span 1;
}

.column-filter-delete {
  grid-column: 5 / span 1;
  position: relative;
  width: 20px;
  height: 20px;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    height: 50%;
    width: 100%;
    height: 2px;
    background: #ff0000;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
