.manage-tags-edit-input {
  font-size: 16px;
  border: none;
  width: 90%;
  cursor: pointer;
}

.manage-tags-delete-button {
  float: right;
  cursor: pointer;
}

.manage-tags-delete-hidden {
  visibility: hidden;
}
