.returning-customer-page {
  display: grid;
  overflow: auto;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}

.add-payment-method-wrapper {
  margin-top: 5px;
  position: relative;
  padding: 10px;
  border: 1px solid #000000;
  background: #f7f7f7;
}

.add-payment-method-close {
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 5px;
  border: none;
  background: none;
  display: block;
  width: 15px;
  height: 15px;
  padding: 0px;
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background: #000000;
    top: 50%;
    left: 50%;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
