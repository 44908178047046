.manage-tags-modal {
  box-shadow: 5px 8px 5px #00000033;
  background: white;
  z-index: 1;
  border: 1px solid #dbddd7;
  width: 40%;
  position: absolute;
  left: 30%;
}

.manage-tags-header {
  background: #eceee8;
  font-weight: bold;
  padding: 10px 0px 10px 20px;
}

.manage-tags-exit {
  background: #888888;
  width: 25px;
  height: 25px;
  float: right;
  margin-right: 10px;
  cursor: pointer;
  text-align: center;
}

.manage-tags-addtag {
  text-align: center;
  border: 1px dashed black;
  color: #0077aa;
  font-weight: bold;
  margin: 20px 20px 10px 20px;
  padding: 10px 0px;
  cursor: pointer;
}

.tag-card-area {
  height: 500px;
  overflow: auto;
}

.tag-card {
  border-top: 1px solid black;
  margin: 0px 20px;
  padding: 10px 0px 10px 20px;
}

.tag-card-closer {
  border-top: 1px solid black;
  margin: 0px 20px;
}

.tag-card:last-of-type {
  border-bottom: 1px solid black;
}

.manage-tags-footer {
  background: #eceee8;
  text-align: right;
  padding-right: 5px;
}

.manage-tags-cancel,
.manage-tags-save {
  cursor: pointer;
  border: none;
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  margin: 10px 5px;
}

.manage-tags-cancel {
  background-color: #888888;
}

.manage-tags-save {
  background: #44aa44;
}
