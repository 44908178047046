.subscription-card {
  padding: 10px;
  border-top: 1px solid #000000;
  display: grid;
  grid-template-columns: 1fr 50px;

  &:first-child {
    border-top: none;
  }
}

.subscription-card-line-item {
  position: relative;
  margin: 0px;
}

.subscription-card-name {
  margin: 0px;
}

.subscription-card-description {
  font-style: italic;
  color: #555555;
  font-size: 12px;
  margin: 0px;
}

.subscription-card-price {
  position: absolute;
  top: 50%;
  right: 10px;
  bottom: 0px;
  margin: 0px;
  transform: translate(0px, -50%);
}
