.transaction-header, .upcoming-charges-header {
    margin-left: 10px;
}

.transaction-history, .upcoming-charges-segment {
    border: 1px solid black;
    background-color: white;
    margin: 0px 10px;
}

.upcoming-charges-segment {
    padding: 10px
}

.transaction-segment {
    margin: 10px;
    border-bottom: 1px solid black;
}

.transaction-history-disclaimer {
    margin-left: 10px;
}