.payment-method-section {
  position: relative;
  padding-bottom: 45px;
  overflow: auto;
  background: #ffffff;
  border: 1px solid #000000;
}

.payment-method-list {
  margin: 0px;
  position: relative;
  max-height: 600px;
  overflow: auto;
  border: none;
  padding: 10px;
}

.payment-method-buttons {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  justify-content: space-between;
}

.payment-method-new-button {
  background: #44aa44;
}

.payment-method-default-button {
  background: #00aaff;
  margin-left: 10px;
}

.payment-method-delete-button {
  background: #dd0000;
  margin-left: 10px;
}

.payment-method-new-button,
.payment-method-default-button,
.payment-method-delete-button {
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;

  &:disabled {
    background: #cccccc;
    color: #333333;
    cursor: default;
  }
}
