@use "_variables";

* {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  // color: variables.color("color");
}

body {
  padding: 0px;
  margin: 0px;
  background: variables.color("background");
}

.layout {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 45px calc(100vh - 45px);
  grid-template-areas:
    "glob glob glob"
    "side main main";
}

.side-nav {
  grid-area: side;
  overflow: auto;
}

.side-nav.open {
  width: 200px;
}

.side-nav.closed {
  width: 0px;
}

.main {
  grid-area: main;
  background: variables.color("background");
  overflow: hidden;
}

.main > .page {
  overflow: auto;
  height: calc(100% - 42px);
}

.global-nav {
  grid-area: glob;
}

.error-message {
  color: #dd0000;
  margin: 0px;
}
