.loading-cover {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 520px;
  background: #fafafa;
  z-index: 1;

  &.loading-card {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: unset;
  }
}

.spinner-back {
  fill: none;
  stroke: #aaaaaa;
}

.spinner {
  fill: none;
  stroke: #00aaaa;
  animation: spin 0.75s linear infinite;
  transform-origin: 50% 50%;
}

.spinner-wrapper {
  position: absolute;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
  width: 60px;
  height: 60px;
}

.spinner-no-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
