.manage-tags-button {
  background-color: #0077aa;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  float: right;
}

.filter-page-subheading {
  margin-top: 50px;
}

.filter-page-heading {
  margin-bottom: 20px;
}

.results-per-page {
  float: right;
}

.results-per-page-count {
  font-size: 15px;
  margin-left: 10px;
  padding: 8px 10px 8px 0px;
  border: 1px solid #cccccc;
}

.employee-search-input {
  width: 30%;
  margin-top: 10px;
  padding: 8px;
}

.employee-tag-table {
  background-color: #ffffff;
  font-size: 15px;
  width: 100%;
  margin-top: 20px;
  border: 1px solid black;
}

.employee-tag-table-header {
  margin-top: 20px;
  background-color: #eeeeee;
  border: 1px solid #cccccc;
}

.employee-tag-table-heading,
.employee-tag-table-name,
.employee-tag-table-extId {
  padding: 10px 0px 10px 10px;
  width: 20%;
}

.employee-tag-table-heading,
.employee-tag-table-heading-tags {
  font-weight: bold;
}

.employee-tag-table-heading {
  border-right: 1px solid #cccccc;
}

.employee-tag-table-row {
  border: 1px solid #cccccc;
  border-top: 0;
}

.employee-tag-table-row:hover {
  background-color: #e6f2ff;
  .edit-tags-button {
    visibility: visible;
    display: block;
    float: right;
    cursor: pointer;
  }
  .not-selected {
    background: #e6f2ff;
  }
}

.edit-tags-button {
  background-color: #e6f2ff;
  color: #0077aa;
  border: 1px solid #0077aa;
  padding: 5px;
  margin-right: 10px;
  visibility: hidden;
  float: right;
}

.employee-tag-table-item-tags,
.employee-tag-table-item-tags-clickable {
  display: inline-block;
  background-color: #555555;
  color: #ffffff;
  padding: 5px;
  margin-right: 5px;
}

.employee-tag-table-item-tags-clickable {
  cursor: pointer;
}
