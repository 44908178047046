.userDropdownClosed {
  visibility: hidden;
}

.userDropdownContainer {
  cursor: pointer;
  background-color: white;
  color: black;
  border: 1px solid black;
  position: absolute;
  top: 35px;
  right: 15px;
  z-index: 1;
}

.userDropdownOption {
  width: 100%;
  cursor: pointer;
  border: none;
  padding: 5px;
  color: black;
  background-color: white;
}

.userDropdownOption:focus-visible {
  outline: none;
}

.userDropdownOption:hover {
  color: white;
  background-color: #00aaff;
}

.global-notification {
  margin-left: 5px;
  padding: 1px 5px;
  border-radius: 100%;
  background-color: red;
}
