.page-nav {
  background: #666a70;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 20px;
  height: 42px;
}

.page-title {
  font-size: 20px;
  margin: 0px 10px 0px 0px;
}

.page-link {
  position: relative;
  border-right: 1px solid #555555;
  color: #888c92;
  text-decoration: none;
  height: 100%;
  padding: 10px;
  margin: 0px;
  font-weight: bold;
  font-size: 13px;
  line-height: calc(42px - 2 * 10px);
}

.page-link:first-of-type {
  border-left: 1px solid #555555;
}

.page-link.active {
  background: #55595e;
  color: #ffffff;
}

.page-link.active::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  left: 0px;
  top: 0px;
  background: #00aaff;
}

.email-report-link {
  background: transparent;
  border: none;
  color: #ffffff;
  padding: 10px;
  cursor: pointer;
  margin-left: auto;
  font-size: 16px;
}

.page-nav-utility-btns {
  margin-left: auto;
}

@media screen and (max-width: 1145px) {
  .page-link {
    padding: 8px 10px;
    line-height: calc(42px - 2 * 14px);
  }
  .page-title {
    font-size: 18px;
    margin: 0px 10px 0px 0px;
  }
}
@media screen and (max-width: 840px) {
  .page-link {
    padding-top: 4px;
    font-size: 13px;
    line-height: 11px;
  }
}
