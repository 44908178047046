.graph-wrapper {
  overflow: hidden;
  position: relative;
}

path.domain {
  stroke: #00000000;
}

.tick line {
  stroke: #00000011;
}

.line-group .point {
  stroke: #ffffff;
  stroke-width: 2;
}

text {
  user-select: none;
}

.graph-svg {
  width: 100%;
  height: 100%;
}
