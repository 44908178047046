.sub-menu-item {
  display: block;
  text-decoration: none;
  color: #eeeeee;
  background: #ffffff11;
  border-left: 4px solid #ffffff55;
  padding: 5px 10px;
  font-size: 14px;
}

.sub-menu-item:hover {
  background: #ffffff22;
  border-left: 4px solid #ffffff77;
}

.sub-menu-item.active {
  background: #00aadd;
  border-left: 4px solid #55ddff;
}
