.dashboard-access-label {
  margin-bottom: 10px;
}

.access-tags-wrapper {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 5px;
  max-width: 340px;
}

.dashboard-access-item {
  color: #ffffff;
  background-color: #555555;
  padding: 0px 2px;
  cursor: pointer;
}

.dashboard-access-options,
.dashboard-name-new {
  display: grid;
  max-width: 400px;
  grid-template-columns: 130px 1fr;
  margin-bottom: 10px;
}

.dashboard-name-new {
  margin-bottom: 8px;
}

.dashboard-name-input {
  margin-left: 0px;
}
