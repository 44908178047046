.product-card {
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
  border: none;
  margin: 20px auto;
  max-width: 400px;
  padding: 10px;

  * {
    margin: 0px;
  }

  .product-name,
  .product-description,
  .product-subtext,
  .product-subtotal {
    margin-bottom: 15px;
  }

  .product-price {
    margin-bottom: 5px;
  }

  .product-quantity {
    margin-bottom: 25px;
    text-align: center;
  }

  .purchase-button {
    border: none;
    background: #44aa44;
    padding: 5px 15px;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;

    &:disabled {
      background: #cccccc;
      color: #888888;
      cursor: default;
    }
  }
}
