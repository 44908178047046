.reports-page {
  height: 100%;
  padding: 0px 20px;
  overflow: auto;
}

.reports-wrapper {
  // margin: 0px 20px;
  border: 1px solid #555555;
}
