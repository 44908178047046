.tooltip,
.tooltip * {
  pointer-events: none;
}

.tooltip-background {
  /* width: 150px; */
  /* height: 64px; */
  // stroke: #000;
  fill: #fff;
  pointer-events: none;
  user-select: none;
  rx: 3;
  ry: 3;
}
