.chip-drop-down-wrapper {
  min-height: 2em;
}

.chip-drop-down {
  text-align: left;
  &.open {
    overflow: auto;
    position: absolute;
    border: 1px solid;
    z-index: 1;
    background: #fefefe;
    padding: 2px 2px 0px 2px;

    .chip {
      margin-bottom: 2px;
      margin-right: 2px;
    }
  }
  min-width: 100%;
}

.chip-example-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.chip-example {
  display: inline-block;
  position: relative;
  border: 1px solid #555555;
  background: #555555;
  color: #ffffff;
  padding: 5px 20px 5px 5px;
}

.chip-count {
  display: inline-block;
  position: absolute;
  border: 1px solid #555555;
  background: #777777;
  color: #ffffff;
  padding: 5px 5px 5px calc(100% + 5px);
}
