.card {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 5px #00000022;
  overflow: hidden;

  .inner-wrapper {
    padding: 10px;
    height: 100%;
    // overflow: auto;

    .graph-table-wrapper {
      border: 1px solid #dddddd;
    }

    .card-graph,
    .data-table {
      border: none;
    }

    .data-table th {
      border-top: none;
    }
  }

  .heading {
    position: relative;
    margin: 0px;
    font-size: 18px;
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-start;
    display: grid;
    grid-template-columns: 1fr auto auto;
  }

  .subheading {
    margin: 0px;
    font-size: 14px;
    font-weight: normal;
    color: #777777;
  }

  .header-wrapper {
    position: relative;
    margin-top: 2em;
    margin-bottom: 10px;
  }

  .header {
    position: relative;
    display: inline-block;
    font-size: 46px;
    margin: 0px;
    // margin-bottom: 0px;
  }

  .subheader {
    font-size: 18px;
    font-weight: normal;
    color: #777777;
    margin: 0px;
  }

  .hover-text {
    display: none;
  }

  .header:hover .hover-text,
  .subheader:hover .hover-text,
  .header:hover ~ .hover-text {
    z-index: 1;
    display: block;
    position: absolute;
    font-size: 14px;
    font-weight: normal;
    padding: 5px 10px;
    background: #333333;
    color: #ffffff;
    bottom: 100%;
    left: 0px;
    line-height: 1.25em;
    border-radius: 2px;
    max-width: 100%;
  }
}

.card-button {
  display: block;
  cursor: pointer;
  position: relative;
  overflow: visible;
  border: none;
  position: relative;
  width: 1em;
  height: 1em;
  box-shadow: 0px 0px 3px 0px #00000055;
  background: none;
  font-size: 18px;

  &::after {
    content: "Hover Text Here";
    display: block;
    position: absolute;
    padding: 0.5em;
    top: 50%;
    right: 50%;
    background: #fafafa;
    font-size: 16px;
    font-weight: normal;
    color: #000000;
    border: 1px solid #000000;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0s linear 0s;
    pointer-events: none;
    z-index: 1;
  }

  &:hover {
    &::after {
      opacity: 1;
      transition: opacity 0s linear 1s;
    }
  }
}

.settings-button {
  // cursor: pointer;
  // position: relative;
  // background: none;
  // display: block;
  // width: 1em;
  // height: 1em;
  // border: none;
  // position: relative;
  // overflow: visible;
  // box-shadow: 0px 0px 3px 0px #00000055;

  &::before {
    content: "";
    position: absolute;
    width: 0.5em;
    height: 0.5em;
    border-radius: 0.5em;
    background-color: #00000055;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &::after {
    content: "Card Settings";
    // display: block;
    // position: absolute;
    // padding: 0.5em;
    // top: 50%;
    // right: 50%;
    // background: #fafafa;
    // font-size: 16px;
    // font-weight: normal;
    // color: #000000;
    // border: 1px solid #000000;
    // white-space: nowrap;
    // opacity: 0;
    // transition: opacity 0s linear 0s;
    // pointer-events: none;
    // z-index: 1;
  }

  &:hover {
    &::before {
      background-color: #00000077;
    }

    // &::after {
    //   opacity: 1;
    //   transition: opacity 0s linear 1s;
    // }
  }
}

.card-link {
  // display: block;
  // width: 1em;
  // height: 1em;
  // position: relative;
  // overflow: visible;
  // box-shadow: 0px 0px 3px 0px #00000055;

  &::before {
    content: "";
    display: block;
    position: absolute;
    border: 0.4em solid transparent;
    border-left: 0.4em solid #00000055;
    top: 50%;
    left: 50%;
    transform: translate(-25%, -50%);
  }

  &::after {
    content: "Navigate to report";
    // display: block;
    // position: absolute;
    // padding: 0.5em;
    // top: 50%;
    // right: 50%;
    // background: #fafafa;
    // font-size: 16px;
    // font-weight: normal;
    // color: #000000;
    // border: 1px solid #000000;
    // white-space: nowrap;
    // opacity: 0;
    // transition: opacity 0s linear 0s;
    // pointer-events: none;
    // z-index: 1;
  }

  &:hover {
    &::before {
      border-left: 0.4em solid #00000077;
    }

    // &::after {
    //   opacity: 1;
    //   transition: opacity 0s linear 1s;
    // }
  }
}

.no-settings-button {
  display: none;
}
