@use "../../_variables";

.side-nav {
  background: variables.color("side-nav", "background");
}

.side-nav-heading {
  position: relative;
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #00000022;
  /* margin: -10px -15px 0px -15px; */
  padding: 10px 15px;
}

.side-nav-header {
  font-size: 18px;
  margin: 0px;
  color: variables.color("side-nav", "heading", "color");
}

.side-nav-close {
  position: absolute;
  display: block;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 50px;
  cursor: pointer;
  border-left: 1px solid #00000022;
  /* background: #ff0000; */
}

.side-nav-close:hover {
  background: #00000022;
}

.side-nav-close::before,
.side-nav-close::after {
  position: absolute;
  content: "";
  display: block;
  width: 25px;
  height: 3px;
  background: variables.color("side-nav", "heading", "color");
  top: 50%;
  left: 50%;
}

.side-nav-close:hover::before,
.side-nav-close:hover::after {
  background: variables.color("highlight");
}

.side-nav-close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.side-nav-close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.expand-button-hidden {
  display: none;
}

.expand-button-visible {
  position: absolute;
  margin: 237px 0px 0px 0px;
  background: #33444c;
  width: 25px;
  height: 50px;
  z-index: 2;
  cursor: pointer;
}

.sidenav-expand-triangle {
  position: absolute;
  top: 50%;
  left: 50%;
  border-top: 15px solid transparent;
  border-left: 15px solid #aaaaaa;
  border-bottom: 15px solid transparent;
  transform: translate(-50%, -50%);
}
