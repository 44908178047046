.license-modal-heading {
  margin: 0px;
}

.license-modal-text {
  margin: 15px 0px 0px 0px;
  font-size: 14px;
}

.license-modal-input {
  width: 50px;
  border: 1px solid #000000;
  border-radius: 3px;
  padding: 5px;
  text-align: center;
}

.license-modal.modal {
  .modal-content {
    padding: 10px;
  }

  .license-modal-buttons {
    background: #eeeeee;
    margin: 10px -10px -10px;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .cancel-button {
    background: linear-gradient(#eeeeee, #cccccc);
    border: 1px solid #cccccc;
    color: #333333;
    border-radius: 1px;
    padding: 5px 15px;
    cursor: pointer;
  }

  .buy-license-button {
    background: linear-gradient(#00bb00, #009900);
    color: #ffffff;
    border: none;
    padding: 5px 15px;
    border-radius: 1px;
    margin-left: 10px;
    cursor: pointer;
  }

  .cancel-button:hover {
    box-shadow: 0px 1px 2px 1px #00000033;
  }

  .buy-license-button:hover {
    box-shadow: 0px 1px 2px 1px #00aa00aa;
  }
}

.license-modal.modal-background {
  background: linear-gradient(#00ccff55, #00000055), repeating-linear-gradient(to right, transparent 0px 1px, #00000099 1px 2px);
}
